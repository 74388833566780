














import {Vue, Component, Prop} from "vue-property-decorator";
import BikeSucHeadType from "./indexType";

@Component({name: "BikeSucHead"})
export default class BikeSucHead extends Vue implements BikeSucHeadType{
    data:any = {}

    mounted(){
        let { data } = this.$route.query
        try{
            let JsonData = JSON.parse( data as string )
            this.data = JsonData
        }catch (e) {
            console.info("转换失败")
        }
    }

    // 代取货
    get getPickUp(){
        if ( this.getDatabase ){
            return this.getDatabase.totalPickUp || 0
        }else{
            return this.data.pickUp || 0
        }
    }

    //待送达
    get getArrive(){
        if ( this.getDatabase ){
            return this.getDatabase.totalArrive || 0
        }else{
            return this.data.arrive || 0
        }
    }

    @Prop(Object)
    database!:any
    get getDatabase(){ return this.database }
}
