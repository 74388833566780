

















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import BikeSucStateType from "./indexType";

@Component({name: "BikeSucState"})
export default class BikeSucState extends Vue implements BikeSucStateType{

    @Prop(String)
    title!:string
    get getTitle(){ return this.title || '标题' }

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }

    @Prop(String)
    color!:string
    get getColor(){ return this.color || '#333' }
}
