import {Toast} from "vant";
import {FriendLocationStore} from "@/views/Friend/FriendAddress/Store";

//成功时
export function onSuccess(position: any) {
    //返回用户位置
    //经度
    let longitude = position.coords.longitude;
    //纬度
    let latitude = position.coords.latitude;
    FriendLocationStore.setLocationData({ longitude,latitude })
}

// 错误
export function onError(error: any) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            Toast.fail("用户拒绝对获取地理位置的请求");
            break;
        case error.POSITION_UNAVAILABLE:
            Toast.fail("位置信息是不可用的");
            break;
        case error.TIMEOUT:
            Toast.fail("请求用户地理位置超时");
            break;
        case error.UNKNOWN_ERROR:
            Toast.fail("未知错误");
            break;
    }

    this.title = "定位失败"
}

export function getLocation(That: any): void {
    let options = {
        enableHighAccuracy: true, //boolean 是否要求高精度的地理信息，默认为false
        maximumAge: 1000 //应用程序的缓存时间
    }
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(onSuccess.bind(That), onError.bind(That), options);
    } else {
        Toast.fail("当前不支持")
        That.title = "当前不支持定位"
    }
}
