






















import {Vue, Component, Watch} from "vue-property-decorator";
import BikeSucType from "./BikeSuc";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import BikeSucHead from "@/views/Dispatching/BikeSuc/components/Head/index.vue";
import BikeSucState from "@/views/Dispatching/BikeSuc/components/State/index.vue";
import { AwaitFrom,AwaitTo } from './Model/index';
import {isMap} from "@/location/location";
import { getLocation } from "@/views/Friend/FriendAddress/util/Gd";
import {FriendLocationStore} from "@/views/Friend/FriendAddress/Store";
import {setHTML} from "@/views/Dispatching/BikeSuc/util/index";
import {getBikeSuc} from "@/views/Dispatching/BikeSuc/Server";

declare const AMap:any

@Component({name: "BikeSuc",components:{ HeadTop,BikeSucHead,BikeSucState }})
export default class BikeSuc extends ZoomPage implements BikeSucType{
    AwaitFrom = AwaitFrom
    AwaitTo = AwaitTo
    redirectIcon = require("@/views/Dispatching/Img/redirect.png");
    pathIcon = require("@/views/Dispatching/Img/path.png");
    bikeIcon = require("@/views/Dispatching/Img/bike.png")
    map:any={}
    marker:any={}
    data:any ={}

    mounted(){
        this.loadingShow = false
        let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
        if ( !( bool != null && bool[0] == "micromessenger" ) ){
            getLocation(this)
        }
        this.handleUpData()
    }

    init(){
        this.map = new AMap.Map("BikeSucView",{
            zoom: 16,
            center:[this.getLocation.longitude, this.getLocation.latitude],
            lang:'zh_cn',
        })
        this.map.setZoomAndCenter(16, [this.getLocation.longitude, this.getLocation.latitude]);
        // 构造点标记
        this.marker = new AMap.Marker({
            position: [this.getLocation.longitude, this.getLocation.latitude],
            content:setHTML(this.bikeIcon),
        });
        this.map.add(this.marker)
        this.map.on("click",this.handleClickMap)
    }

    handleUpData(){
        let { data } = this.$route.query
        let JsonData:any = {};
        try{
            JsonData = JSON.parse( data as string )
        }catch (e) {
            console.info("转换失败")
        }
        getBikeSuc(JsonData.userId || 0).then(res=>{
            this.data = res
        })
    }

    handleClickRedirect(){
        this.map.setZoomAndCenter(16, [this.getLocation.longitude, this.getLocation.latitude]);
    }

    handleClickMap(e:any){
        console.log( e )
    }

    // 待送货
    get getArrive(){
        if ( this.data && this.data.getSth?.length ){
            return this.data.getSth
        }else{
            return this.AwaitFrom
        }
    }

    // 待送达
    get getPickedUp(){
        try{
            if ( this.data && this.data.served && this.data.served?.length ){
                return this.data.served
            }else{
                return this.AwaitTo
            }
        }catch (e) {
            return this.AwaitTo
        }
    }

    get getLocation(){
        return FriendLocationStore.getLocationData
    }
    @Watch("getLocation")
    changeLocation(){
        isMap().then(res=>{
            this.init()
        })
    }
}
